.hero-section {
  position: relative;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay for better text visibility */
}

.hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  opacity: 0.9; /* Slightly transparent for text to stand out */
}

.hero-content {
  position: relative;
  z-index: 1;
  color: white;
  padding: 2rem;
  backdrop-filter: blur(5px); /* Adds a subtle blur effect behind text */
  border-radius: 10px; /* Softens the edges of the content box */
  transition: transform 0.3s ease-in-out;
}

.hero-content:hover {
  transform: scale(1.05); /* Slight zoom on hover for interactive feel */
}

.hero-content h1 {
  font-size: 4rem; /* Increased size for more impact */
  font-weight: bold;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Shadow for depth */
}

.hero-content p {
  font-size: 1.75rem;
  margin-bottom: 2rem;
  line-height: 1.4;
}

.explore-button {
  background-color: #e50914;
  color: white;
  border: none;
  padding: 12px 25px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds depth to button */
}

.explore-button:hover {
  background-color: #f40612;
  transform: translateY(-3px); /* Lifts button on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* More pronounced shadow on hover */
}

/* Responsive design for different screen sizes */
@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 3rem;
  }

  .hero-content p {
    font-size: 1.5rem;
  }

  .explore-button {
    padding: 10px 20px;
    font-size: 1rem;
  }
}
