.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #141414; /* Dark background for contrast */
  padding: 1rem 2rem; /* Adjust for better spacing */
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
  position: sticky; /* Make header stick to the top */
  top: 0;
  z-index: 100; /* Ensure it stays above other content */
}

.logo-container .logo {
  height: 60px; /* Slightly larger for visibility */
  transition: transform 0.3s ease; /* Smooth transform on hover */
}

.logo-container .logo:hover {
  transform: scale(1.1); /* Slight increase in size on hover */
}

.navigation .nav-links {
  list-style: none;
  display: flex;
  gap: 1.5rem; /* Increased gap for better spacing */
}

.navigation .nav-links a {
  color: white;
  text-decoration: none;
  font-weight: 500; /* Makes the font slightly bolder */
  transition: color 0.3s ease, transform 0.3s ease;
}

.navigation .nav-links a:hover {
  color: #e50914; /* Accent color for interactivity */
  transform: translateY(-2px); /* Slight move up on hover for depth */
}
.auth-container {
  display: flex;
  align-items: center;
  gap: 1rem; /* Space between elements if there are multiple, like 'Sign In' and 'Sign Up' */
}

.auth-container a, .auth-container button {
  color: white;
  background-color: transparent;
  border: 1px solid white; /* Stylish border */
  padding: 0.5rem 1rem;
  text-decoration: none;
  font-size: 1rem;
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;
  border-radius: 4px; /* Soft rounded corners for a modern look */
}

.auth-container a:hover, .auth-container button:hover {
  color: #141414; /* Text color on hover */
  background-color: white; /* Background color on hover */
}

/* If you have separate states or styles for 'signed in' vs 'not signed in' */
.auth-signed-in {
  color: green; /* Or any color indicating 'active' or 'signed in' status */
}

.auth-signed-out {
  color: red; /* Or any color indicating 'inactive' or 'signed out' status */
}



/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .navigation .nav-links {
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
  }
}
