.content-section {
    padding: 40px 0;
    background-color: #1e1e1e; /* A dark background color */
    color: white; /* Light text for contrast */
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }
  
  .section-title {
    margin-bottom: 30px;
    text-align: center;
    font-size: 2.5rem; /* A larger font size for headings */
  }
  
  .section-content {
    /* Styling for the content goes here */
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Provide space between content items */
  }
  
  /* Add responsive design features here */
  @media (max-width: 768px) {
    .section-title {
      font-size: 2rem; /* Slightly smaller font for mobile */
    }
  
    .section-content {
      /* Adjust layout for smaller screens */
      flex-direction: column;
    }
  }
  